import { atom, useAtom } from 'jotai';

export interface CourseRound {
  courseName: string;
  round: string;
}
export const infoModalAtom = atom<boolean>(false);
export const infoModalMsgAtom = atom<number>(0);
export const infoModalCourseInfoAtom = atom<CourseRound>({} as CourseRound);
export const orientationModalAtom = atom<boolean>(false);
export const isAlreadyPopupAtom = atom<boolean>(false);
export const MobileGNBOpenAtom = atom<boolean>(false);

export const hasReachedFooterAtom = atom<boolean>(false);

export function useNotificationModal() {
  const [isModalOpen, setShowModal] = useAtom(infoModalAtom);
  const [modalMessage, setModalMessage] = useAtom(infoModalMsgAtom);
  const [modalCourseInfo, setModalCourseInfo] = useAtom(
    infoModalCourseInfoAtom,
  );

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return {
    modalMessage,
    modalCourseInfo,
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
    setOpen: setShowModal,
    setModalMessage,
    setModalCourseInfo,
  };
}
