import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useOnlineUser } from '@/hooks';

interface Profile {
  [key: string]: string | number | boolean | null | undefined;
}

interface CallbackUser {
  alert: number;
  avatarUrl: string;
  id: string;
  language: string;
  memberId: string;
  name?: string;
  profile?: Profile | null;
  tags?: string[] | null;
  unsubscribeEmail: boolean;
  unsubscribeTexting: boolean;
}

export const bootChannelTalk = (userId = '') => {
  if (
    typeof window !== 'undefined' &&
    !JSON.parse(process.env.IS_DEV) &&
    window.ChannelIO !== undefined
  ) {
    window.ChannelIO(
      'boot',
      {
        pluginKey: '74fefd0d-c880-4ec7-b06f-d09eeba19b43',
        customLauncherSelector: '#footer-channel-button, #channel-button',
        memberId: userId,
        hideChannelButtonOnBoot: true,
      },
      function onBoot(error: Error | null, user: CallbackUser | null) {
        if (error) {
          console.error(error);
        } else if (!JSON.parse(process.env.IS_PROD)) {
          console.info('boot success ', user);
        }
        window.ChannelIO('onShowMessenger', () => {
          const customChannelTalkButton =
            document.getElementById('channel-button');

          if (!customChannelTalkButton) return;

          customChannelTalkButton.style.display = 'none';
        });

        window.ChannelIO('onHideMessenger', () => {
          const customChannelTalkButton =
            document.getElementById('channel-button');

          if (!customChannelTalkButton) return;

          customChannelTalkButton.style.display = 'block';
        });
      },
    );
  }
};

export const ChannelScript = () => {
  const router = useRouter();
  const userInfo = useOnlineUser();

  const isChExcludedPathName = (pathName: string) => ['/v2'].includes(pathName);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (!isChExcludedPathName(router.pathname)) {
      bootChannelTalk(userInfo?._id);
    }
  }, [router, userInfo]);

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function () {
    var w = window;
    if (w.ChannelIO) {
        return (window.console.error || window.console.log || function () {
        })('ChannelIO script included twice.');
    }
    var ch = function () {
        ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
        ch.q.push(args);
    };
    w.ChannelIO = ch;

    function l() {
        if (w.ChannelIOInitialized) {
            return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    }

    if (document.readyState === 'complete') {
        l();
    } else if (window.attachEvent) {
        window.attachEvent('onload', l);
    } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
    }
})();`,
      }}
    />
  );
};
