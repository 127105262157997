import { ArrowChevronRightSmall, ArrowLeft } from '@teamsparta/design-system';
import InternalServerErrorSvg from 'public/svg/error/InternalServerErrorSvg';
import NotFoundSvg from 'public/svg/error/NotFoundSvg';
import type { ReactNode } from 'react';

interface ErrorData {
  figure: ReactNode;
  title: string;
  description: string;
  destination: string | 'previous';
  buttonContent?: ReactNode;
}

export const errorDataMap: Record<404 | 500, ErrorData> = {
  404: {
    figure: <NotFoundSvg />,
    title: '페이지를 찾을 수 없어요',
    description:
      '이 페이지는 사라졌거나 다른 링크로 변경되었습니다.\n입력하신 페이지의 주소를 다시 확인해 주세요.',
    destination: '/',
    buttonContent: (
      <>
        <span>홈으로 가기</span> <ArrowChevronRightSmall size={16} />
      </>
    ),
  },
  500: {
    figure: <InternalServerErrorSvg />,
    title: '잠시 후 다시 시도해 주세요',
    description:
      '서버 문제로 요청을 처리하지 못했습니다.\n잠시 후 다시 시도하거나 지속될 경우 문의해 주세요.',
    destination: 'previous',
    buttonContent: (
      <>
        <ArrowLeft size={16} /> <span>이전으로</span>
      </>
    ),
  },
};
