import { useAtomValue, useSetAtom } from 'jotai';

import { assert } from '@/lib/utils/assert';
import { onlineUserInfoAtom } from '@/store/user';

export function useOnlineUser() {
  return useAtomValue(onlineUserInfoAtom);
}

export function useHydratedOnlineUser() {
  const onlineUserInfo = useAtomValue(onlineUserInfoAtom);

  assert(
    onlineUserInfo,
    'onlineUserInfoAtom is not hydrated. Please use useHydrateAtoms.',
  );

  return onlineUserInfo;
}

export function useSetOnlineUser() {
  return useSetAtom(onlineUserInfoAtom);
}
