import { noop } from '@teamsparta/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useBoolean, usePreserveCallback } from '@/hooks/common';

interface UseTimerProps {
  /**
   * 초기 시간
   */
  initialTime?: number;
  /**
   * 시작 콜백
   */
  onTimerStart?: () => void;
  /**
   * 초기화 콜백
   */
  onTimerStop?: () => void;
  /**
   * 일시정지 콜백
   */
  onTimerPause?: () => void;
  /**
   * 초기 시간이 변경되었을 때 시간을 동기화할지 여부
   */
  syncTime?: boolean;
}

/**
 * 스탑워치 훅
 * @description 타이머를 시작하고, 일시정지하고, 초기화할 수 있습니다. 증가하는 시간을 표시할 때 사용합니다.
 * @param initialTime 초기 시간
 * @param onTimerStart 시작 콜백
 * @param onTimerStop 초기화 콜백
 * @param onTimerPause 일시정지 콜백
 * @param syncTime 초기 시간이 변경되었을 때 시간을 동기화할지 여부
 */
export function useStopwatch({
  initialTime,
  onTimerPause = noop,
  onTimerStart = noop,
  onTimerStop = noop,
  syncTime = false,
}: UseTimerProps) {
  const [time, setTime] = useState(initialTime ?? 0);
  const [isRunning, , setTrue, setFalse] = useBoolean(false);
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const onTimerStartCallback = usePreserveCallback(onTimerStart);
  const onTimerStopCallback = usePreserveCallback(onTimerStop);
  const onTimerPauseCallback = usePreserveCallback(onTimerPause);

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      return;
    }

    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    setTrue();
    onTimerStartCallback?.();
  }, [onTimerStartCallback, setTrue]);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setTime(0);
    timerRef.current = null;
    setFalse();
    onTimerStopCallback?.();
  }, [onTimerStopCallback, setFalse]);

  const pauseTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = null;
    setFalse();
    onTimerPauseCallback?.();
  }, [onTimerPauseCallback, setFalse]);

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, []);

  useEffect(() => {
    if (syncTime && (initialTime || initialTime === 0)) {
      setTime(initialTime);
    }
  }, [initialTime, syncTime]);

  return {
    isRunning,
    time,
    startTimer,
    stopTimer,
    pauseTimer,
  };
}
