import axios, {
  AxiosError,
  type AxiosInstance as AxiosInstanceType,
  type AxiosRequestConfig,
} from 'axios';

import {
  ApiException,
  CustomException,
  ForbiddenException,
  NetworkException,
  TimeoutException,
  UnauthorizedException,
} from '@/lib/exceptions';
import { ApiResponseV2 } from '@/types/common/api';

interface AxiosInstance extends AxiosInstanceType {
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
  patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
}

export const instanceV2 = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER,
}) as AxiosInstance;

instanceV2.interceptors.response.use(
  (response) => {
    const apiResponse: ApiResponseV2<unknown> = response.data;

    if (apiResponse.success === true) {
      return apiResponse.data;
    } else {
      throw new ApiException(
        apiResponse.errorMessage || '알 수 없는 API 오류',
        response.status,
      );
    }
  },
  (error: AxiosError) => {
    if (error.code === 'ECONNABORTED') {
      throw new TimeoutException(
        '요청 시간이 초과되었습니다',
        error.response?.status,
      );
    } else if (error.code === 'ERR_NETWORK' || error.request) {
      throw new NetworkException('네트워크 오류가 발생했습니다');
    } else if (error.response) {
      handleErrorResponse(error);
    } else {
      throw new CustomException('예기치 않은 오류가 발생했습니다');
    }
  },
);

function handleErrorResponse(error: AxiosError) {
  const apiResponse = error.response?.data as ApiResponseV2<unknown>;
  const status = error.response?.status;

  switch (status) {
    case 401:
      throw new UnauthorizedException(
        '인증되지 않은 접근 - 로그인 해주세요',
        status,
      );
    case 403:
      throw new ForbiddenException(
        '접근 금지 - 이 리소스에 접근할 권한이 없습니다',
        status,
      );
    case 502:
      throw new CustomException(
        '서버 게이트웨이 오류 - 잠시 후 다시 시도해주세요',
        status,
      );
    default:
      throw new ApiException(
        (apiResponse?.success === false && apiResponse.errorMessage) ||
          '알 수 없는 API 오류',
        status,
      );
  }
}
