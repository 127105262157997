/**
 * 파일 위치도 확인해 주세요!
 */

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Timeout = 408,
  InternalServerError = 500,
  BadGateway = 502,
}

class BaseException extends Error {
  timestamp: string;
  statusCode: StatusCode;

  constructor(message: string, statusCode: StatusCode) {
    super(message);
    this.name = this.constructor.name;
    this.timestamp = new Date().toISOString();
    this.statusCode = statusCode;
  }
}

export class CustomException extends BaseException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.InternalServerError,
  ) {
    super(message, statusCode);
  }
}

export class NetworkException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.InternalServerError,
  ) {
    super(message, statusCode);
  }
}

export class ApiException extends CustomException {
  constructor(message: string, statusCode: StatusCode = StatusCode.NotFound) {
    super(message, statusCode);
  }
}

export class TimeoutException extends CustomException {
  constructor(message: string, statusCode: StatusCode = StatusCode.Timeout) {
    super(message, statusCode);
  }
}

export class UnauthorizedException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.Unauthorized,
  ) {
    super(message, statusCode);
  }
}

export class ForbiddenException extends CustomException {
  constructor(message: string, statusCode: StatusCode = StatusCode.Forbidden) {
    super(message, statusCode);
  }
}
