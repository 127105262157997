import { noop } from '@teamsparta/utils';
import { useEffect, useRef } from 'react';

import { usePreserveCallback } from '@/hooks/common';

interface UseIntersectionObserverProps {
  root?: IntersectionObserverInit['root'];
  rootMargin?: IntersectionObserverInit['rootMargin'];
  threshold?: IntersectionObserverInit['threshold'];
  onIntersect?: () => void;
  onIntersectEnd?: () => void;
}

const defaultOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

/**
 * @deprecated `@teamsparta/react`에서 제공하는 `useIntersectionObserver`를 사용해 주세요.
 */
export function useIntersectionObserver<E extends HTMLElement>({
  onIntersect = noop,
  onIntersectEnd = noop,
  root = defaultOptions.root,
  rootMargin = defaultOptions.rootMargin,
  threshold = defaultOptions.threshold,
}: UseIntersectionObserverProps) {
  const ref = useRef<E>(null);
  const onIntersectCallback = usePreserveCallback(onIntersect);
  const onIntersectEndCallback = usePreserveCallback(onIntersectEnd);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onIntersectCallback?.();
        } else {
          onIntersectEndCallback?.();
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [
    onIntersectCallback,
    onIntersectEndCallback,
    root,
    rootMargin,
    threshold,
  ]);

  return ref;
}
