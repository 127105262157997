import { DAY, HOUR, SECOND } from '@/constants/time';

export const dayNames = ['일', '월', '화', '수', '목', '금', '토'];

export function convertDateIntoObj(dateString: string | null | Date) {
  if (!dateString) return null;
  const date = new Date(dateString);
  const year = String(date.getFullYear()).slice();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const dayName = dayNames[date.getDay()];
  const hour = String(date.getHours());
  const minute = String(date.getMinutes());
  const second = String(date.getSeconds());
  return {
    year,
    month,
    day,
    dayName,
    hour,
    minute,
    second,
  };
}

/**
 * date에 dateFn을 적용한 새로운 Date 객체를 반환합니다.
 * @param date Date 객체
 * @param dateFn Date 객체에 적용할 함수
 */
export function getNewDate(date: Date | string, dateFn: (date: Date) => void) {
  const newDate = new Date(date);
  dateFn(newDate);
  return newDate;
}

/**
 * date에 hours를 더하거나 뺀 새로운 Date 객체를 반환합니다.
 * @description 보통 서버에서 Date를 받아올 때 한국시간이지만 UTC+0으로 표현되어 있어 Date 객체로 변환시 보정이 필요합니다.
 * 기존 Date 객체를 수정하지 않고 새로운 Date 객체를 반환합니다.
 * @param date Date 객체
 * @param hours 더할 시간
 */
export function adjustTimeByHours(date: Date | string, hours: number) {
  return getNewDate(date, (d) => d.setHours(d.getHours() + hours));
}

/**
 * date 간 차이를 일 단위로 반환합니다.
 */
export function getDifferenceInDays(dateLeft: Date, dateRight: Date) {
  const diff = dateLeft.getTime() - dateRight.getTime();

  return Math.floor(diff / DAY);
}

/**
 * date 간 차이를 시간 단위로 반환합니다.
 */
export function getDifferenceInHours(dateLeft: Date, dateRight: Date) {
  const diff = dateLeft.getTime() - dateRight.getTime();

  return Math.floor(diff / HOUR);
}

/**
 * date 간 차이를 초 단위로 반환합니다.
 */
export function getDifferenceInSeconds(dateLeft: Date, dateRight: Date) {
  const diff = dateLeft.getTime() - dateRight.getTime();

  return Math.floor(diff / SECOND);
}

/**
 * 오늘이 해당 날짜들 사이에 있는지 여부를 반환합니다.
 */
export function isTodayBetweenDates(
  startDate?: Date | string,
  endDate?: Date | string,
) {
  if (!startDate || !endDate) return false;

  const sDate = new Date(startDate).getTime();
  const eDate = new Date(endDate).getTime();
  const today = new Date().getTime();

  return sDate <= today && today <= eDate;
}

/**
 * @description 양일 간의 date 객체들을 담은 배열을 반환합니다.
 * @example generateDates(new Date('2021-01-01'), new Date('2021-01-03')) // [new Date('2021-01-01'), new Date('2021-01-02'), new Date('2021-01-03')]
 */
export function generateDates(startDate: Date, endDate: Date) {
  const dates: Date[] = [];
  const date = new Date(startDate);

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

/**
 * 첫 번째 날짜가 두 번째 날짜보다 이전인지 확인합니다.
 * @param date 비교할 날짜
 * @param targetDate 기준 날짜
 * @returns 첫 번째 날짜가 두 번째 날짜보다 이전이면 true, 그렇지 않으면 false
 *
 * @example
 * ```ts
 * const date1 = new Date('2024-07-24');
 * const date2 = new Date('2024-07-25');
 *
 * console.log(isBefore(date1, date2)); // true
 * console.log(isBefore(date2, date1)); // false
 * ```
 */
export function isBefore(date: Date, targetDate: Date) {
  return date.getTime() < targetDate.getTime();
}

/**
 * 첫 번째 날짜가 두 번째 날짜보다 이후인지 확인합니다.
 * @param date 비교할 날짜
 * @param targetDate 기준 날짜
 * @returns 첫 번째 날짜가 두 번째 날짜보다 이후면 true, 그렇지 않으면 false
 *
 * @example
 * ```ts
 * const date1 = new Date('2024-07-24');
 * const date2 = new Date('2024-07-25');
 *
 * console.log(isAfter(date1, date2)); // false
 * console.log(isAfter(date2, date1)); // true
 * ```
 */
export function isAfter(date: Date, targetDate: Date) {
  return date.getTime() > targetDate.getTime();
}

/**
 * 주어진 생년월일로부터 현재까지의 나이를 계산합니다.
 * @description 만나이로 계산합니다.
 * @param birthDate 사용자의 생년월일
 * @return 계산된 나이
 */
export function getAge(birthDate: Date): number {
  const today = new Date();

  const yearDiff = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    return yearDiff - 1;
  }

  return yearDiff;
}
