import { isClient } from '@teamsparta/utils';

export const initIMP = () => {
  if (isClient()) {
    const IMP = window.IMP;
    IMP && IMP.init('imp54748251');
  }
};

export const IamportScript = () => {
  return (
    <>
      <script
        defer
        type='text/javascript'
        src='https://code.jquery.com/jquery-1.12.4.min.js'
      ></script>
      <script
        defer
        type='text/javascript'
        src='https://cdn.iamport.kr/js/iamport.payment-1.1.8.js'
      ></script>
    </>
  );
};
