/**
 * 로그 출력에 사용되는 ANSI 색상 코드를 정의합니다.
 */
export const LogColors = {
  // Base colors
  gray: '\x1b[38;2;106;115;125m', // GitHub dark gray
  red: '\x1b[38;2;239;83;80m', // Material red
  yellow: '\x1b[38;2;255;203;107m', // One Dark yellow
  blue: '\x1b[38;2;130;170;255m', // One Dark blue
  green: '\x1b[38;2;98;150;85m', // GitHub dark green
  cyan: '\x1b[38;2;86;182;194m', // Nord cyan
  magenta: '\x1b[38;2;199;146;234m', // Material purple

  // Special formatting
  reset: '\x1b[0m', // Reset all styles
  dim: '\x1b[2m', // Dimmed text
  bold: '\x1b[1m', // Bold text
  italic: '\x1b[3m', // Italic text
} as const;

/**
 * 로그 레벨을 정의하는 타입입니다.
 */
export type LogLevel = 'debug' | 'info' | 'warn' | 'error' | 'success';

/**
 * 애플리케이션 전반에서 사용되는 로깅 유틸리티 클래스입니다.
 * @description 로그 레벨에 따라 로그를 출력합니다
 * @example
 * ```ts
 * Logger.setLogLevel('debug');
 * Logger.debug('Namespace', 'Message');
 * Logger.info('Namespace', 'Message');
 * Logger.warn('Namespace', 'Message');
 * Logger.error('Namespace', 'Message');
 * Logger.success('Namespace', 'Message');
 * ```
 */
export class Logger {
  private static enabled = true;
  private static logLevel: LogLevel = 'info';

  /**
   * 로거의 활성화 상태를 설정합니다.
   * @param enabled - 로거 활성화 여부
   */
  static setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }

  /**
   * 로그 레벨을 설정합니다.
   * @description 설정된 로그 레벨에 따라 요청하는 로그가 무시될 수 있습니다
   * @param level - 설정할 로그 레벨
   */
  static setLogLevel(level: LogLevel): void {
    this.logLevel = level;
  }

  /**
   * 텍스트에 색상을 적용합니다.
   * @param text - 색상을 적용할 텍스트
   * @param color - 적용할 색상
   * @returns 색상이 적용된 텍스트
   */
  static format(text: string, color: keyof typeof LogColors): string {
    return `${LogColors[color]}${text}${LogColors.reset}`;
  }

  /**
   * 디버그 레벨 로그를 출력합니다.
   * @param namespace - 로그가 발생한 네임스페이스
   * @param message - 로그 메시지
   */
  static debug(namespace: string, message: string): void {
    this.logWithLevel('debug', namespace, message);
  }

  /**
   * 정보 레벨 로그를 출력합니다.
   * @param namespace - 로그가 발생한 네임스페이스
   * @param message - 로그 메시지
   */
  static info(namespace: string, message: string): void {
    this.logWithLevel('info', namespace, message);
  }

  /**
   * 경고 레벨 로그를 출력합니다.
   * @param namespace - 로그가 발생한 네임스페이스
   * @param message - 로그 메시지
   */
  static warn(namespace: string, message: string): void {
    this.logWithLevel('warn', namespace, message);
  }

  /**
   * 에러 레벨 로그를 출력합니다.
   * @param namespace - 로그가 발생한 네임스페이스
   * @param message - 로그 메시지
   */
  static error(namespace: string, message: string): void {
    this.logWithLevel('error', namespace, message);
  }

  /**
   * 성공 레벨 로그를 출력합니다.
   * @param namespace - 로그가 발생한 네임스페이스
   * @param message - 로그 메시지
   */
  static success(namespace: string, message: string): void {
    this.logWithLevel('success', namespace, message);
  }

  /**
   * 각 로그 레벨의 우선순위를 정의합니다.
   * 숫자가 클수록 높은 우선순위를 가집니다.
   */
  private static readonly LOG_LEVELS: Record<LogLevel, number> = {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
    success: 1,
  };

  /**
   * 각 로그 레벨에 대응하는 출력 색상을 정의합니다.
   */
  private static readonly LOG_COLORS: Record<LogLevel, keyof typeof LogColors> =
    {
      debug: 'dim',
      info: 'blue',
      warn: 'yellow',
      error: 'red',
      success: 'green',
    };

  /**
   * 지정된 레벨의 로그를 출력합니다.
   * @param level - 로그 레벨
   * @param namespace - 로그가 발생한 네임스페이스
   * @param message - 로그 메시지
   */
  private static logWithLevel(
    level: LogLevel,
    namespace: string,
    message: string,
  ): void {
    // 로거가 비활성화되었거나 현재 로그 레벨보다 우선순위가 낮은 경우 로그를 출력하지 않습니다
    if (
      !this.enabled ||
      this.LOG_LEVELS[level] < this.LOG_LEVELS[this.logLevel]
    ) {
      return;
    }
    const timestamp = new Date().toISOString().split('T')[1].slice(0, -1);
    const prefix = `${LogColors.dim}[${timestamp}]${LogColors.reset} ${LogColors.cyan}[${namespace}]${LogColors.reset}`;
    const color = LogColors[this.LOG_COLORS[level]];

    const logMessage = `${prefix} ${color}${
      LogColors.bold
    }${level.toUpperCase()}${LogColors.reset}${color}:${
      LogColors.reset
    } ${message}`;

    // eslint-disable-next-line no-console
    console.log(logMessage);
  }
}
