import {
  FULL_TIME,
  getCourseDetail,
  PLUS_BACKEND,
  PLUS_FRONTEND,
} from '@/constants';
import { sendLog } from '@/lib/utils/send-log';
import { CourseRoute, CourseType, PlusKoreanPosition } from '@/types';

import { isPlus } from '../course/course';

const APPLY_LOG_EVENT_NAME: Record<
  typeof FULL_TIME | typeof PLUS_BACKEND | typeof PLUS_FRONTEND,
  string
> = {
  [PLUS_BACKEND]: 'hhplus_mainPage_applynow_click',
  [PLUS_FRONTEND]: 'hhplus_mainPage_applynow_click',
  [FULL_TIME]: 'hh_mainPage_applynow_click',
};

const NOTI_LOG_EVENT_NAME: Record<
  typeof PLUS_BACKEND | typeof PLUS_FRONTEND,
  string
> = {
  [PLUS_BACKEND]: 'hhplus_be_mainPage_alarmApplynow_click',
  [PLUS_FRONTEND]: 'hhplus_fe_mainPage_alarmApplynow_click',
};

export function sendMainCurriTabClickLog(week: string, curriculum: string) {
  sendLog('hh_mainPage_curriculumTab_click', {
    week,
    curriculum,
  });
}
export function sendLandingScrollLog(div_depth: number, div_name: string) {
  sendLog('hh_mainPage_scroll', { div_depth, div_name });
}

export function sendGNBClickLog(
  button_text: string,
  button_href: string,
  page_title,
) {
  sendLog('hh_gnb_click', { button_text, button_href, page_title });
}

//항해 메인페이지 접속 시 발생
export function sendMainPageViewLog() {
  sendLog('hh_mainPage_view', {});
}

//항해 플러스  메인페이지 접속 시 발생
export function sendPlusMainPageViewLog(courseType: CourseType) {
  // plus 일 때만 쓰일 변수라 PLUS_FRONTEND, PLUS_BACKEND 밖에 없어서 HHPLUS_FRONTEND로만 삼항 연산자 처리
  const courseTypeKR = courseType === PLUS_FRONTEND ? '프론트엔드' : '백엔드';
  sendLog('hhplus_mainPage_view', { course_type: courseTypeKR });
}
//항해 허브 메인페이지 접속 시 발생
export function sendHubPageViewLog() {
  sendLog('hh_hub_view', {});
}

//항해 커리큘럼 업데이트 내역 우피 링크로 연결되는 cta 클릭 시 발생
export function sendMainUpdateClickLog(
  button_text: string,
  button_href: string,
) {
  sendLog('hh_mainPage_update_click', {
    button_text,
    button_href,
  });
}

//항해99 랜딩페이지 내 지원하기 버튼 클릭
export function sendMainApplyClickLog(
  button_text: string,
  button_name: string,
  location: string,
) {
  sendLog('hh_mainPage_applynow_click', {
    button_text,
    button_name,
    location,
  });
}

export function sendPlusMainCurriToggleOpenLog(
  week: string,
  curriculum: string,
  courseType: PlusKoreanPosition,
) {
  sendLog('hhplus_mainPage_curriculumToggle_open', {
    week,
    curriculum,
    course_type: courseType,
  });
}

export function sendHubDifferenceDivClickLog(
  button_text: string,
  title: string,
) {
  sendLog('hh_hub_differenceDiv_click', { button_text, title });
}
export function sendHubCourseDetailClickLog(course_title: string) {
  sendLog('hh_hub_courseDetail_click', { course_title });
}
export function sendHubHamburgerClickLog() {
  sendLog('hh_hub_menuHamburger_click', {});
}

// 코스 지원 로그
export function sendApplyLog(
  course: CourseRoute,
  button_name,
  button_text,
  location,
) {
  // plus 일 때만 쓰일 변수라 PLUS_FRONTEND, PLUS_BACKEND 밖에 없어서 HHPLUS_FRONTEND로만 삼항 연산자 처리
  const { type, koreanPosition } = getCourseDetail(course);

  sendLog(APPLY_LOG_EVENT_NAME[type], {
    button_name,
    button_text,
    ...(isPlus(type) && {
      course_type: koreanPosition,
    }),
    location,
  });
}

// 코스 알림신청 로그
export function sendNotificationLog(
  course,
  button_name,
  button_text,
  location,
) {
  sendLog(NOTI_LOG_EVENT_NAME[course], {
    button_text,
    button_name,
    location,
  });
}

//플러스 타임테이블 상세 일정 클릭 시 전송되는 로그
export function sendPlusTimeTableClickDetailLog(
  button_text: string,
  course_type: string,
) {
  sendLog('hhplus_mainPage_TimeTableDetailButton_click', {
    button_text,
    course_type,
  });
}
