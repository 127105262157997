import { useEffect, useMemo, useRef } from 'react';

/**
 * usePreserveCallback
 * @description useCallback을 사용하지 않은 콜백 함수라도 레퍼런스를 보존하여 불필요한 렌더링을 방지합니다.
 * @param callback 콜백 함수
 *
 * useEffect나 useMemo 등 의존성 배열에 useCallback을 사용하지 않은 콜백함수가 추가되더라도
 * 최신 상태의 실행은 보존할 수 있고, 새롭게 함수가 생성되지 않아 불필요한 연산을 줄일 수 있습니다.
 *
 * @example ts
 * const useSomething = (someCallback: () => void) => {
 *  const callback = usePreserveCallback(someCallback);
 *
 *  useEffect(() => {
 *    callback();
 *  }, [callback]); // 리렌더로 인해 인자로 넘어온 콜백함수가 새롭게 생성되어 레퍼런스가 변경되더라도 useEffect에서 최신 상태의 함수를 사용할 수 있으며
 *                  // 불필요한 렌더링을 방지할 수 있습니다.
 * }
 */
export function usePreserveCallback<T extends (...args: any[]) => any>(
  callback: T,
) {
  const ref = useRef<T>(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    return (...args: Parameters<T>) => {
      return ref.current?.(...args);
    };
  }, [ref]);
}
