import { FULL_TIME, PLUS_BACKEND, PLUS_FRONTEND, REBOOT } from '@/constants';

/** @deprecated */
export function isFullTime(roundType) {
  return roundType === FULL_TIME;
}

/**
 * @deprecated @/constants/course.ts 에서 제공하는 isPlus 함수를 사용해 주세요.
 */
export function isPlus(roundType) {
  return roundType === PLUS_BACKEND || roundType === PLUS_FRONTEND;
}

/**
 * @deprecated @/constants/course.ts 에서 제공하는 isReboot 함수를 사용해 주세요.
 */
export function isReboot(roundType) {
  return roundType === REBOOT;
}
