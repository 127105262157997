import { useEffect, useState } from 'react';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

const DESKTOP_WIDTH = 1024;

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  function handleWindowSizeChange() {
    setIsDesktop(window.innerWidth > DESKTOP_WIDTH);
  }

  useIsomorphicLayoutEffect(() => {
    handleWindowSizeChange();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isDesktop;
};
