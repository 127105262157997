import { instance } from '@/api/v2/instance';

import {
  GetNotificationByRoundIdAndOnlineUserIdResponseDto,
  PostNotificationRequestDto,
  PostNotificationResponseDto,
} from './notification.dto';

export async function getNotificationByRoundIdAndOnlineUserId(
  roundId: string,
  onlineUserId: string,
) {
  const { data } =
    await instance.get<GetNotificationByRoundIdAndOnlineUserIdResponseDto>(
      `/v3/notifications/${onlineUserId}/${roundId}`,
    );

  return data.data;
}

export async function postNotification(body: PostNotificationRequestDto) {
  const { data } = await instance.post<PostNotificationResponseDto>(
    '/v3/notifications',
    body,
  );

  return data;
}
