import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { neutralDay, wBody4 } from '@teamsparta/design-system';

export const ButtonWrapper = styled.section`
  min-width: 26px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0;
`;

export const TooltipBox = styled.span<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 280px;
  background-color: ${neutralDay.gray90};
  color: #fff;
  padding: 16px 16px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: 42px;
  right: -9.5px;
  margin-left: -60px;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  border: 1px solid ${neutralDay.gray80};
  display: flex;
  flex-direction: column;
  gap: 12px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10px;
    border-width: 10px;
    border-style: solid;
  }

  &::before {
    border-color: transparent transparent ${neutralDay.gray80} transparent;
  }

  &::after {
    border-color: transparent transparent ${neutralDay.gray90} transparent;
    bottom: calc(100% - 2px);
  }
`;
export const LinkButtonStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  b {
    ${wBody4};
    color: ${neutralDay.white};
  }
`;

export const LinkButtonList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const LinkButton = styled.button`
  ${LinkButtonStyle};
`;

export const Divider = styled.hr`
  height: 1px;
  background-color: ${neutralDay.gray80};
  margin: 22px 0 20px;
  border: 0;
`;

export const GnbLogoutButton = styled.button`
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  ${wBody4};
  color: ${neutralDay.gray40};
  cursor: pointer;
`;

export const Blank = styled.div`
  width: 32px;
  background-color: transparent;
`;
