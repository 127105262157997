import { useEffect, useState } from 'react';

export function useActiveSection({ dataAttribute }: { dataAttribute: string }) {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  function goToSection(section: string, offset: number = 0) {
    const target = document.querySelector(`[${dataAttribute}=${section}]`);

    if (!target) {
      return;
    }

    if (offset) {
      window.scrollTo({
        top: target.getBoundingClientRect().top + window.scrollY + offset,
        behavior: 'smooth',
      });
      return;
    }

    target.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const targets = document.querySelectorAll(`[${dataAttribute}]`);

    const observer = new IntersectionObserver(
      (entries) => {
        const mostVisibleEntry = entries.reduce((mostVisible, entry) => {
          return entry.isIntersecting &&
            entry.intersectionRatio > mostVisible.intersectionRatio
            ? entry
            : mostVisible;
        }, entries[0]);

        if (mostVisibleEntry.isIntersecting) {
          setActiveSection(mostVisibleEntry.target.getAttribute(dataAttribute));
        }
      },
      {
        rootMargin: '-45% 0px -45%',
      },
    );

    targets.forEach((target) => {
      observer.observe(target);
    });

    return () => {
      targets.forEach((target) => {
        observer.unobserve(target);
      });
    };
  }, [dataAttribute]);

  return { activeSection, goToSection };
}
