type QueryParams = { [key: string]: string | string[] | null };

function parseQueryString(queryString: string): QueryParams {
  const params = new URLSearchParams(queryString);
  const parsedParams: QueryParams = {};

  params.forEach((value, key) => {
    if (parsedParams[key]) {
      if (Array.isArray(parsedParams[key])) {
        (parsedParams[key] as string[]).push(value);
      } else {
        parsedParams[key] = [parsedParams[key] as string, value];
      }
    } else {
      parsedParams[key] = value;
    }
  });

  return parsedParams;
}

export function parseQueryStringToObject<T>(queryString: string): T {
  const params = parseQueryString(queryString);
  return params as unknown as T;
}
