import { noop } from '@teamsparta/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { usePreserveCallback } from '@/hooks/common';

interface UseHoverProps {
  onHoverStart?: () => void;
  onHoverEnd?: () => void;
}

export default function useHover<T extends HTMLElement>({
  onHoverStart = noop,
  onHoverEnd = noop,
}: UseHoverProps = {}) {
  const [isHovered, setIsHovered] = useState(false);
  const targetRef = useRef<T>(null);

  const onHoverStartCallback = usePreserveCallback(onHoverStart);
  const onHoverEndCallback = usePreserveCallback(onHoverEnd);

  const handleMouseOver = useCallback(() => {
    setIsHovered(true);
    onHoverStartCallback?.();
  }, [onHoverStartCallback]);

  const handleMouseOut = useCallback(() => {
    setIsHovered(false);
    onHoverEndCallback?.();
  }, [onHoverEndCallback]);

  useEffect(() => {
    const targetElement = targetRef.current;
    if (!targetElement) return;

    targetElement.addEventListener('mouseover', handleMouseOver);
    targetElement.addEventListener('mouseout', handleMouseOut);

    return () => {
      targetElement.removeEventListener('mouseover', handleMouseOver);
      targetElement.removeEventListener('mouseout', handleMouseOut);
    };
  }, [handleMouseOut, handleMouseOver]);

  return {
    targetRef,
    isHovered,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  };
}
