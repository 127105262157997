import styled from '@emotion/styled';
import { mBody3, neutralDay } from '@teamsparta/design-system';

import { DESKTOP } from '@/styles/themes';

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResultFigureWrapper = styled.div`
  margin-bottom: 32px;
  svg {
    width: 90px;
    height: 60px;
  }

  ${DESKTOP} {
    svg {
      width: 105px;
      height: 70px;
    }
  }
`;

export const ResultBottomAddonContainer = styled.div`
  display: flex;
  margin-top: 24px;

  ${DESKTOP} {
    margin-top: 32px;
  }
`;

export const ResultTitleWrapper = styled.div``;

export const ResultButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  height: 36px;
  padding: 16px 12px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${neutralDay.gray10};
  ${mBody3};
  color: ${neutralDay.gray100};
  span {
    padding-inline: 2px;
  }
`;

export const ResultDescription = styled.div<{
  hasTitle: boolean;
  hasBottomAddon?: boolean;
}>`
  white-space: pre-line;
  text-align: center;
  margin-top: ${({ hasTitle }) => (hasTitle ? '12px' : '0')};
  margin-bottom: ${({ hasBottomAddon }) => (hasBottomAddon ? '32px' : '0')};
`;
