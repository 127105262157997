import { atom, useAtom } from 'jotai';

export const isLoginModalShowAtom = atom(false);
export const nextPageAfterLoginAtom = atom<string | undefined>('');

export function useLoginModal() {
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [nextPageAfterLogin, setNextPageAfterLogin] = useAtom(
    nextPageAfterLoginAtom,
  );

  function closeLoginModal() {
    setIsLoginModalShow(false);
  }

  function openLoginModal(
    /**
     * @param nextPage 로그인 성공 후 이동할 페이지
     */
    nextPage?: string,
  ) {
    if (nextPage) {
      setNextPageAfterLogin(nextPage);
    }
    setIsLoginModalShow(true);
  }

  return {
    isOpen: isLoginModalShow,
    nextPage: nextPageAfterLogin,
    close: closeLoginModal,
    open: openLoginModal,
    setNextPage: setNextPageAfterLogin,
  };
}
