import {
  GetApplicantBaseDto,
  GetApplicantLogDto,
  PatchApplicantFunnelRequestDto,
} from '@/api/common/applicant/applicant.dto';
import { instance } from '@/api/v2/instance';
import { Course } from '@/types';

export async function getApplicantByOnlineUserId<T extends GetApplicantBaseDto>(
  onlineUserId: string,
  courseName: Course,
  roundId?: string,
) {
  const { data } = await instance.get<T>(`/v3/applicants/${onlineUserId}`, {
    params: { courseName, roundId },
  });

  return data;
}

export async function getOnGoingApplicantByOnlineUserId<
  T extends GetApplicantBaseDto,
>(onlineUserId: string) {
  const { data } = await instance.get<T>(
    `/v3/applicants/${onlineUserId}/ongoing`,
  );

  if (!data) {
    return null;
  }

  return data;
}

export async function patchApplicantFunnel<
  T extends PatchApplicantFunnelRequestDto,
>(body: T) {
  const { data } = await instance.patch(`/v3/applicants/funnel`, body);

  return data;
}

export async function getApplicantLogsByApplicantId<
  T extends GetApplicantLogDto,
>(applicantId: string) {
  const { data } = await instance.get<T[]>(`/v3/applicantsLogs/${applicantId}`);

  if (!data) {
    return null;
  }

  return data;
}
