import { noop } from '@teamsparta/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useBoolean, usePreserveCallback } from '@/hooks/common';

interface UseTimerProps {
  /**
   * 만료 시간
   */
  expireTime?: number;
  /**
   * 시작 콜백
   */
  onStart?: () => void;
  /**
   * 초기화 콜백
   */
  onReset?: () => void;
  /**
   * 일시정지 콜백
   */
  onPause?: () => void;
  /**
   * 만료 콜백
   */
  onExpire?: () => void;
  /**
   * 만료 시간이 변경되었을 때 시간을 동기화할지 여부
   */
  syncTime?: boolean;
  /**
   * 자동 시작 여부
   */
  autoStart?: boolean;
  /**
   * 타이머 간격
   */
  interval?: number;
  /**
   * 감소 값
   */
  decrementValue?: number;
}

/**
 * 타이머 훅
 * @description 타이머를 시작하고, 일시정지하고, 초기화할 수 있습니다. 감소하는 시간을 표시할 때 사용합니다.
 * @param expireTime 만료 시간
 * @param onPause 일시정지 콜백
 * @param onStart 시작 콜백
 * @param onReset 초기화 콜백
 * @param onExpire 만료 콜백
 * @param syncTime 만료 시간이 변경되었을 때 시간을 동기화할지 여부
 * @param autoStart 자동 시작 여부
 * @param decrementValue 감소 값
 * @param interval 타이머 간격
 */
export function useTimer({
  expireTime,
  onPause = noop,
  onStart = noop,
  onReset = noop,
  onExpire = noop,
  syncTime = false,
  autoStart = false,
  decrementValue = 1,
  interval = 1000,
}: UseTimerProps) {
  const [time, setTime] = useState(expireTime ?? 0);
  const [isRunning, , setTrue, setFalse] = useBoolean(false);
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const onStartCallback = usePreserveCallback(onStart);
  const onResetCallback = usePreserveCallback(onReset);
  const onPauseCallback = usePreserveCallback(onPause);
  const onExpireCallback = usePreserveCallback(onExpire);

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      return;
    }
    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
          setFalse();
          onExpireCallback?.();
          return 0;
        }
        return prevTime - decrementValue;
      });
    }, interval);
    setTrue();
    onStartCallback?.();
  }, [
    decrementValue,
    interval,
    onExpireCallback,
    onStartCallback,
    setFalse,
    setTrue,
  ]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setTime(expireTime ?? 0);
    timerRef.current = null;
    setFalse();
    onResetCallback?.();
  }, [expireTime, onResetCallback, setFalse]);

  const pauseTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    setFalse();
    onPauseCallback?.();
  }, [onPauseCallback, setFalse]);

  useEffect(() => {
    if (!autoStart) {
      return;
    }
    startTimer?.();
  }, [autoStart, startTimer]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (syncTime && expireTime) {
      setTime(expireTime);
    }
  }, [expireTime, syncTime]);

  return {
    isRunning,
    time,
    startTimer,
    resetTimer,
    pauseTimer,
  };
}
