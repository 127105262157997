import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { updateOffsetTopListAtom } from '../landing';

export const useInnerWidth = () => {
  const [width, setWidth] = useState<number>(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    // setWidth(390);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width;
};

export const scrollYAtom = atom(0);

export const useWindowScrollY = () => {
  const [scrollY, setScrollY] = useAtom(scrollYAtom);
  const [, updateOffsetTopList] = useAtom(updateOffsetTopListAtom);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const sectionElemList = [];
      updateOffsetTopList(sectionElemList);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollY;
};
