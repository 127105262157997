import { noop } from '@teamsparta/utils';
import { useEffect, useRef } from 'react';

import { usePreserveCallback } from './usePreserveCallback';

interface UseResizeObserverProps {
  onResize?: (entry: ResizeObserverEntry) => void;
}

export const useResizeObserver = <E extends HTMLElement = HTMLDivElement>({
  onResize = noop,
}: UseResizeObserverProps) => {
  const containerRef = useRef<E>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const onResizeCallback = usePreserveCallback(onResize);

  useEffect(() => {
    const element = containerRef.current;

    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];

      if (!entry) {
        return;
      }

      onResizeCallback(entry);
    });

    resizeObserver.observe(element);
    resizeObserverRef.current = resizeObserver;

    return () => {
      resizeObserver.disconnect();
      resizeObserverRef.current = null;
    };
  }, [onResizeCallback]);

  return containerRef;
};
