import { css, Global } from '@emotion/react';
import { neutralDay } from '@teamsparta/design-system';

const DesignSystemCss = () => {
  return <Global styles={style} />;
};

const style = css`
  input:focus {
    outline: none;
  }

  textarea {
    border-radius: 8px;
    border: 1px solid ${neutralDay.gray20};
  }

  textarea:placeholder-shown {
    color: ${neutralDay.gray50};
  }
  textarea:focus {
    border: 1px solid ${neutralDay.gray90};
  }
`;

export default DesignSystemCss;
