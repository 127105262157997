export function getLocalStorageItem(key: string, defaultValue = '') {
  try {
    const storedValue = JSON.parse(localStorage.getItem(key) || '""');
    return storedValue ?? defaultValue;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

export function setLocalStorageItem<T>(key: string, value: T) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
}

export function removeLocalStorageItem(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
}
