import axios, { AxiosInstance } from 'axios';

import { getCookie } from '@/lib/utils/cookie';

const createInstance = () => {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SERVER,
  });

  return instance;
};

const createOnlineInstance = () => {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_ONLINE_API_URL,
    headers: Object.assign({}),
  });

  return instance;
};

export const authHeader = () => {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    let token = getCookie('token');
    let user_token = getCookie('user_token');
    return {
      Authorization: token ? 'Bearer ' + token : 'Bearer ' + user_token,
    };
  }
};

/**
 * @deprecated instanceV2를 사용해 주세요.
 */
export const instance: AxiosInstance = createInstance();
export const onlineInstance: AxiosInstance = createOnlineInstance();
