import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useCheckNotificationModal({
  onDetectModal,
}: {
  onDetectModal?: (query: string) => void;
}) {
  const router = useRouter();
  const noticeModal = router.query.noticeModal;

  useEffect(() => {
    if (noticeModal) {
      onDetectModal?.(
        // @desc: noticeModal query가 배열로 들어올 수 있으므로, 배열로 들어온 경우에 대한 처리
        typeof noticeModal === 'string' ? noticeModal : noticeModal[0],
      );
    }
  }, [onDetectModal, noticeModal]);
}
