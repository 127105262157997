import styled from '@emotion/styled';

import { DESKTOP } from '@/styles/themes';

export const MobileOnly = styled.div`
  display: contents;

  ${DESKTOP} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;

  ${DESKTOP} {
    display: contents;
  }
`;
