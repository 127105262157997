export const INSIDER_FUNNEL_CONTEXT_AND_QUERY = {
  REBOOT: {
    서류합격: 'applied_id',
    서류불합격: 'applied_id',
    면접예약완료: 'reserved_id',
    결제완료: 'paid_id',
  },
  PLUS: {
    서류완료: 'applied_id',
    결제완료: 'paid_id',
  },
};
