import { useEffect } from 'react';

const initialOptions = {
  smooth: true,
};

export function useScrollToTop({
  smooth,
}: { smooth?: boolean } = initialOptions) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto' });
  }, [smooth]);
}
