import { useEffect } from 'react';

/**
 * 스크롤을 제한시키는 훅
 * @description 모달이나 팝업이 열렸을 때 스크롤을 제한시키기 위해 사용
 * @param isLocked 스크롤을 제한할지 여부
 */
export function useLockBodyScroll(isLocked: boolean) {
  useEffect(() => {
    if (isLocked) {
      const originalStyle = window.getComputedStyle(document.body).overflow;

      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }

    return () => {};
  }, [isLocked]);
}
