import {
  focusManager,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  DesignSystemProvider,
  hanghae,
  neutralDay,
  toast,
  Toaster,
} from '@teamsparta/design-system';
import { isClient } from '@teamsparta/utils';
import { Provider as JotaiProvider } from 'jotai';
import { ReactNode } from 'react';

import { OnlineUserInfoProvider } from '@/components/providers/OnlineUserInfoProvider';
import { Hackle } from '@/lib/sdk';

const breakpoints = {
  desktop: 1024,
  mobile: 0,
};

const theme = {
  mode: 'light',
  colors: {
    light: {
      primary: hanghae.red100,
      secondary: neutralDay.gray100,
      tertiary: neutralDay.gray10,
    },
    dark: {
      primary: hanghae.red100,
      secondary: neutralDay.gray10,
      tertiary: neutralDay.gray90,
    },
  },
} as const;

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      toast.error(
        error.message ?? '문제가 발생했습니다. 고객 센터에 문의 바랍니다.',
        {
          duration: 5000,
        },
      );
      console.error(error);
    },
  }),
});

focusManager.setEventListener((handleFocus) => {
  if (isClient() && window.addEventListener) {
    const visibilitychangeHandler = () => {
      handleFocus(document.visibilityState === 'visible');
    };
    const focusHandler = () => {
      handleFocus(true);
    };
    const blurHandler = () => {
      handleFocus(false);
    };

    window.addEventListener('visibilitychange', visibilitychangeHandler);
    window.addEventListener('focus', focusHandler);
    window.addEventListener('blur', blurHandler);
    return () => {
      window.removeEventListener('visibilitychange', visibilitychangeHandler);
      window.removeEventListener('focus', focusHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }
});

export function Providers({ children }: { children: ReactNode }) {
  return (
    <DesignSystemProvider breakpoints={breakpoints} theme={theme}>
      <QueryClientProvider client={queryClient}>
        <JotaiProvider>
          <OnlineUserInfoProvider>
            <Hackle>{children}</Hackle>
            <Toaster />
          </OnlineUserInfoProvider>
        </JotaiProvider>
      </QueryClientProvider>
    </DesignSystemProvider>
  );
}
