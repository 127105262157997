import { useCallback, useState } from 'react';

/**
 * @description Boolean 을 조금 더 명시적으로 사용할 수 있는 훅
 *
 * 배열 요소의 순서에 따라 역할이 정해집니다.
 * 1. value: Boolean 값
 * 2. toggleValue: Boolean 값을 토글하는 함수
 * 3. setTrue: Boolean 값을 true 로 설정하는 함수
 * 4. setFalse: Boolean 값을 false 로 설정하는 함수
 *
 * @param initialValue 초기값
 * @example
 * const [value, toggleValue, setTrue, setFalse] = useBoolean(false);
 * @returns [value, toggleValue, setTrue, setFalse]
 */
export function useBoolean(initialValue: boolean) {
  const [value, setValue] = useState(initialValue);

  const toggleValue = useCallback(() => setValue(!value), [value]);

  const setTrue = useCallback(() => setValue(true), []);

  const setFalse = useCallback(() => setValue(false), []);

  return [value, toggleValue, setTrue, setFalse] as const;
}
