function validateHexColor(hex: string): boolean {
  return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

function expandShortHex(hex: string): string {
  return hex
    .slice(1)
    .split('')
    .map((char) => char + char)
    .join('');
}

function opacityToHex(opacity: number): string {
  const alpha = Math.round(opacity * 255);
  return alpha.toString(16).padStart(2, '0').toUpperCase();
}

export function applyOpacityToHexColor(hex: string, opacity: number): string {
  if (!validateHexColor(hex)) {
    throw new Error('Invalid HEX color.');
  }

  const fullHex = hex.length === 4 ? `#${expandShortHex(hex)}` : hex;
  const alphaHex = opacityToHex(opacity);

  return `${fullHex}${alphaHex}`;
}
