export function GoogleTagManagerBodyNoscript() {
  return (
    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WC56K7P2"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      }}
    />
  );
}

export function GoogleTagManagerHeadScript() {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WC56K7P2');`,
      }}
    />
  );
}

export const sendGTM = (detail: Record<string, any>) => {
  if (!window.dataLayer) {
    return setTimeout(() => {
      sendGTM(detail);
    }, 300);
  }

  try {
    window.dataLayer.push(detail);
  } catch (error) {
    console.error(error);
  }
};
