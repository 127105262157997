export const videosNotAvailable = {
  '1기': [
    'https://youtu.be/jELf9Zxg8Ss', //SANTA
    'https://youtu.be/UEY_Fpl8zn4', //생각낙서,
    'https://youtu.be/5ARyzQe7ass', //책과 콩나무
  ],
  '2기': [
    'https://youtu.be/0S0rm5WxC5U', // Webtooniverse
    'https://www.youtube.com/watch?v=icaFNUVH4GQ', // 슬기로운 백신생활
  ],
  '3기': [
    'https://youtu.be/EUYZv5PJMtI', // Fungap
    'https://www.youtube.com/watch?v=xW12ZN4M5bE', // 꿀조합
    'https://www.youtube.com/watch?v=6kDlQGqF2YY', //  Meetball
    'https://www.youtube.com/watch?v=e2rUZcTCfXE', //  Getting
    'https://youtu.be/dqNdoTjVQVg', //  Contap
  ],
  '4기': [
    'https://www.youtube.com/watch?v=EPN0XdvE8NE', // Biz Chemy
  ],
  '5기': [
    'https://youtu.be/7YT9sVG5Ar0', // POUG
    'https://youtu.be/lvdoXEwSOqY', // 내가HAMA
    'https://youtu.be/0JcDixFHq-c', // 댕댕한바퀴
    'https://youtu.be/ztvj2KKGHZg', // 기린이의 기획
  ],
  '7기': [
    'https://youtu.be/6yEoZC7CW4Q', // TODOWITH
    'https://youtu.be/QC6GeZKvQvI', // 90분
    'https://youtu.be/ew0TYAPiyU0', // 모두의 육아
  ],
  '8기': [
    'https://youtu.be/VtDBOU1VodU', // 댕과사전
  ],
  '9기': [
    'https://youtu.be/leP41SUsaEY', // 카냐(Ca-Nya)
    'https://www.youtube.com/watch?v=F5fmfktcpBw', // SPOTS 스팟츠
  ],
  '10기': [
    'https://youtu.be/aVE_fJxia44', // 이집은 최종발표영상
  ],
  '11기': [
    'https://www.youtube.com/watch?v=6hCMEsd3C1E', // 대규모 트래픽 서비스
  ],
  우수프로젝트: [
    // "https://www.youtube.com/watch?v=qXa18codJkE", // 사.소.해
  ],
};
