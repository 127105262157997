import { Text } from '@teamsparta/design-system';
import type { ButtonHTMLAttributes, ComponentProps, ReactNode } from 'react';

import * as S from './Result.style';

interface ResultProps {
  figure?: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  bottomAddon?: ReactNode;
}
export default function Result({
  title,
  figure,
  description,
  bottomAddon,
}: ResultProps) {
  return (
    <S.ResultContainer>
      {figure != null && (
        <S.ResultFigureWrapper>{figure}</S.ResultFigureWrapper>
      )}
      {title != null && <S.ResultTitleWrapper>{title}</S.ResultTitleWrapper>}
      {description != null && (
        <S.ResultDescription hasTitle={title != null}>
          {description}
        </S.ResultDescription>
      )}
      {bottomAddon != null && (
        <S.ResultBottomAddonContainer>
          {bottomAddon}
        </S.ResultBottomAddonContainer>
      )}
    </S.ResultContainer>
  );
}

function Title({
  as = 'h1',
  mFont = 'mTitle1',
  wFont = 'wTitle1',
  color = 'neutralDay.white',
  ...restProps
}: Partial<ComponentProps<typeof Text>>) {
  return (
    <Text as={as} mFont={mFont} wFont={wFont} color={color} {...restProps}>
      {restProps.children}
    </Text>
  );
}

function Description({
  as = 'p',
  mFont = 'mBody2',
  wFont = 'wBody2',
  color = 'neutralDay.gray60',
  style: styleFromProps,
  ...restProps
}: Partial<ComponentProps<typeof Text>>) {
  const style = {
    whiteSpace: 'pre-wrap',
    ...styleFromProps,
  };

  return (
    <Text
      as={as}
      mFont={mFont}
      wFont={wFont}
      color={color}
      style={style}
      {...restProps}
    />
  );
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

function Button(props: ButtonProps) {
  return <S.ResultButton {...props} />;
}

Result.Title = Title;
Result.Description = Description;
Result.Button = Button;
