import { useEffect } from 'react';

import { usePreserveCallback } from '@/hooks/common';

interface UseDocumentVisibilityProps {
  onVisibilityChange: (visibility: DocumentVisibilityState) => void;
}

export function useDocumentVisibility({
  onVisibilityChange,
}: UseDocumentVisibilityProps) {
  const onVisibilityChangeCallback = usePreserveCallback(onVisibilityChange);

  useEffect(() => {
    function handleVisibilityChange() {
      onVisibilityChangeCallback(document.visibilityState);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onVisibilityChangeCallback]);
}
