import { useQuery } from '@tanstack/react-query';

import { getAllRoundsByCourseName } from '@/api/common/round';
import { Course } from '@/types';

export const roundQueryKey = {
  all: ['round'],
  payableRoundByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'payable',
    courseName,
  ],
  allRoundsByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'all',
    courseName,
  ],
};

export function useGetAllRoundsByCourseName({
  courseName,
  enabled = true,
}: {
  courseName: Course;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: roundQueryKey.allRoundsByCourseName(courseName),
    queryFn: () => getAllRoundsByCourseName(courseName),
    enabled: !!courseName && enabled,
  });
}
