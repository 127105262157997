import { sendGNBClickLog } from '@/lib/utils/businessLogics/logs/landing';
import { setCookie } from '@/lib/utils/cookie';

export const logout = () => {
  const next = encodeURIComponent(window.location.href);
  setCookie('userinfo', null, null);
  setCookie('token', null, null);
  /**
   * 1. 현재 페이지가 auth가 필요한 페이지인지 확인한다.
   * 2. auth가 필요한 페이지가 아니라면(로그아웃 상태로도 방문할 수 있는 페이지라면), next 값으로 현재 주소를 넣어준다.
   * 3. auth가 필요한 페이지라면(로그아웃 상태로는 방문할 수 없는 페이지라면 e.g. exam, checks 등), next 값으로 항해 허브페이지 주소를 넣어준다.
   */
  const nextHref = authNotRequiredPage(next)
    ? next
    : process.env.NEXT_PUBLIC_PAGE_URL;
  const nextUri = `${process.env.NEXT_PUBLIC_ONLINE_URL}/logout?next=${nextHref}`;
  sendGNBClickLog('로그아웃', nextUri, document.title);
  window.location.href = nextUri;
};

const authNotRequiredPage = (pathname: string) => {
  return (
    pathname.includes('exhibitions') ||
    pathname.includes('orientation') ||
    pathname.includes('graduate') ||
    pathname.includes('faq') ||
    pathname.includes('curriculumn') ||
    pathname.includes('fullTime') ||
    pathname.includes('plus') ||
    pathname.includes('webinar') ||
    pathname.includes('signup_kko') ||
    pathname.includes('pm') ||
    pathname.includes('reboot') ||
    pathname === '/'
  );
};
