import { type PropsWithChildren } from 'react';

import * as S from './style';

/**
 * Only를 사용하여 특정 조건에서만 보여줄 수 있습니다.
 * @description Only.Mobile, Only.Desktop을 사용하여 모바일, 데스크탑에서만 보여줄 수 있습니다.
 * @param children
 * @param condition 조건
 */
export default function Only({
  children,
  condition,
}: PropsWithChildren<{ condition?: boolean }>) {
  return condition ? <>{children}</> : null;
}

Only.Mobile = S.MobileOnly;
Only.Desktop = S.DesktopOnly;
