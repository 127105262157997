import { PartialRecord } from '@teamsparta/types';

import { RecruitingStatus } from '@/hooks/react-query/common/round-status';

export function getCtaText(
  roundName?: string,
): PartialRecord<RecruitingStatus, string> {
  return {
    RECRUITING: '지원하기',
    ALIM: roundName ? `${roundName} 알림 신청하기` : '알림 신청하기',
  };
}
