import {
  INNOVATION,
  PLUS_AI,
  PLUS_BACKEND,
  PLUS_FRONTEND,
  REBOOT,
} from '@/constants';
import { CourseType } from '@/types';

export const COURSE_LANDING_URL: Record<
  Exclude<CourseType, typeof INNOVATION>,
  string
> = {
  [PLUS_BACKEND]: `/plus/be`,
  [PLUS_FRONTEND]: `/plus/fe`,
  [REBOOT]: `/reboot`,
  ['99CLUB_CODING_TEST']: `/99club-codingtest`,
  [PLUS_AI]: 'https://hhplus-ai.oopy.io/',
  ['99CLUB_ALGORITHM_STUDY']: 'https://99club-algorithm.oopy.io/',
};
