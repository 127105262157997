import Script from 'next/script';

export function KakaoPageview({ url }: { url: string }) {
  return (
    <>
      <Script
        async
        id='kakaoInstall'
        type='text/javascript'
        charSet='UTF-8'
        src='//t1.daumcdn.net/adfit/static/kp.js'
      />
      <Script
        async
        id='kakaoPageview'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `kakaoPixel('${process.env.KAKAO_PIXEL_ID}').pageView({url: '${url}'});`,
        }}
      />
    </>
  );
}
